<template>
 <!-- 菜单管理 -->
  <div>
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button class="itemBtn btnColor" @click="addMeunBtn"
        >新建菜单</el-button
      >
      <el-button class="itemBtn btnColor">修改</el-button>
      <el-button class="itemBtn btnColor">删除</el-button>
    </div>
    <!-- 菜单管理 -->
    <tree-table
      :data="data"
      :header-cell-style="{ background: '#f0f0f0' }"
      :columns="columns"
      v-on:parnet-click="handleClick"
      border
    />
  </div>
</template>

<script>
import treeTable from "../../../components/treeTable/treeTable";
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
export default {
  name: "LoginForm",
  data() {
    return {
      columns: [
        {
          text: "菜单名称",
          value: "departmentName",
          align: "left",
        },
        {
          text: "上级菜单",
          value: "departmentType",
        },
        {
          text: "菜单编号",
          value: "description",
        },
        {
          text: "排序号",
          value: "modifyTime",
        },
        {
          text: "备注",
          value: "modifyTime",
        },
        {
          text: "修改人",
          value: "modifyTime",
        },
      ],
      data: [
        {
          id: 1,
          parentId: 0,
          departmentName: "测试1",
          departmentType: 18,
          description: "男",
          modifyTime: "2019-10-10",
          children: [
            {
              id: 2,
              parentId: 1,
              departmentName: "测试2",
              departmentType: 22,
              description: "男",
              modifyTime: "2019-10-10",
              children: [
                {
                  id: 20,
                  parentId: 2,
                  departmentName: "测试2",
                  departmentType: 22,
                  description: "男",
                  modifyTime: "2019-10-10",
                },
              ],
            },
          ],
        },
        {
          id: 3,
          parentId: 0,
          departmentName: "测试3",
          departmentType: 23,
          description: "女",
          modifyTime: "2019-10-10",
          children: [
            {
              id: 4,
              parentId: 3,
              departmentName: "测试4",
              departmentType: 22,
              description: "男",
              modifyTime: "2019-10-10",
            },
            {
              id: 5,
              parentId: 3,
              departmentName: "测试5",
              departmentType: 25,
              description: "男",
              modifyTime: "2019-10-10",
            },
            {
              id: 6,
              parentId: 3,
              departmentName: "测试6",
              departmentType: 26,
              description: "女",
              modifyTime: "2019-10-10",
              children: [
                {
                  id: 7,
                  parentId: 6,
                  departmentName: "测试7",
                  departmentType: 27,
                  description: "男",
                  modifyTime: "2019-10-10",
                },
              ],
            },
          ],
        },
        {
          id: 18,
          parentId: 0,
          departmentName: "测试8",
          departmentType: 18,
          description: "男",
        },
      ],
    };
  },
  components: { treeTable },
  computed: {},
  mounted() {},
  methods: {
    // 表格后面的点击事件
    handleClick(row, type) {

    },
    // 新增菜单
    addMeunBtn() {
      let param = {
        isCustom: "", //是否定制0=否1=是(默认0),
        menuName: "", //菜单名称
        number: "", //菜单编号
        parentId: "", //上级菜单id(默认-1)
        sort: "", //排序值(默认1)
        jumpUrl: "", //菜单跳转url
        tenantId: "", //租户id(isCustom=1时必传)
      };
      Api.addMeun(param).then((res) => {});
    },
  },
};
</script>

<style>
</style>